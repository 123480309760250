const groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

const groupByToArray = key => array => {
    const groupedArray = groupBy(key)(array);
    return Object.values(groupedArray);
}


export default groupBy;
export { groupByToArray }